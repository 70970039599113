@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700;800&family=Poppins:wght@400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");
@import "_colors";

$font-family-header: "Poppins", sans-serif;

html {
  font-size: 100%;
}

* {
  color: rgb(62, 72, 91);
}

a {
  font-size: 16px;
}

body {
  font-family: "Roboto", sans-serif;
  color: $text-secondary;
  font-size: 18px;
}

p {
  line-height: 1.715;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-header;
  line-height: 1.3;
  color: inherit;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
  margin-bottom: 1.8862793572311494rem;
}

h2 {
  font-size: 2.441rem;
  margin-bottom: 1.5086526576019776rem;
}

h3 {
  font-size: 1.953rem;
  margin-bottom: 1.207045735475896rem;
}

h4 {
  font-size: 1.563rem;
  margin-bottom: 1.9320148331rem;
}

h5 {
  font-size: 1.25rem;
  margin-bottom: 0.7725587144622991rem;
  letter-spacing: 0.03em;
}

.text-highlight-dark-background {
  color: $text-highlight-dark-background;
}

.pre-heading-highlight {
  color: $text-highlight;
  font-weight: 500 !important;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  &.box {
    border: 1px solid $text-highlight;
    width: auto;
    display: inline-block;
    padding: 0.3555em 0.41em;
  }
}

.text-color-light {
  color: $text-light;
  &.text-color-light-all-descendant {
    * {
      color: $text-light;
    }
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $secondary-color;
}

a.text-highlight-dark-background:hover {
  text-decoration: none;
  text-decoration-style: none;
  color: $text-highlight-dark-background;
}

.small-text {
  font-size: 12px;
}

.medium-text {
  font-size: 14px;
  &.medium-text-all-descendant {
    font-size: 14px;
  }
}

b {
  font-weight: 500;
}
