#contactUsPage {
    position: relative;
    &::before {
        background-color: #fdfdff;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml,%3Csvg width='1202' height='330' viewBox='0 0 1202 330' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H16C32 1 63 1 93 1C124 1 155 1 186 49C216 97 247 193 278 217C309 241 339 193 370 199C401 205 432 265 463 253C493 241 524 157 555 139C586 121 616 169 647 205C678 241 709 265 739 241C770 217 801 145 832 139C863 133 893 193 924 175C955 157 986 61 1016 61C1047 61 1078 157 1109 193C1139 229 1170 205 1186 193L1201 181' stroke='%230D9EFF' stroke-opacity='0.318'/%3E%3Cpath d='M1 207.047L16 231.047C32 255.047 63 303.047 93 321.047C124 339.047 155 327.047 186 291.047C216 255.047 247 195.047 278 153.047C309 111.047 339 87.0467 370 105.047C401 123.047 432 183.047 463 213.047C493 243.047 524 243.047 555 213.047C586 183.047 616 123.047 647 81.0467C678 39.0467 709 15.0467 739 33.0467C770 51.0467 811.5 93.5 842.5 123.5C873.5 153.5 878.5 154 924 189.047C955 207.047 986 243.047 1016 237.047C1047 231.047 1078 183.047 1109 141.047C1139 99.0467 1170 63.0467 1186 45.0467L1201 27.0467' stroke='%230D9EFF' stroke-opacity='0.318'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 100%;
        z-index: -9;
        opacity: 0.5;
    }
}