@import "../../styles/colors.scss";

.card-item {
	min-height: 50%;
	transition: all 0.3s ease-in-out;
	.card-img-container {
		transition: all 0.4s ease-in-out;
		background-repeat: no-repeat;

		&:before {
			content: "";
			width: 100%;
			display: block;
			height: 0;
			pointer-events: none;
			padding-top: 78.94%;
		}
	}
	.card-title {
		margin-bottom: 18px;
	}
	&:hover {
		.card-title,
		.animated-arrow-link {
			color: $primary-color;
		}
	}
}

@media (min-width: 768px) {
	.card-img-container {
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
}
