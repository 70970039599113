$primary-color: #0d9eff;
$secondary-color: #272e38;
$third-color: #00e0bd;
$accent-color: #e6f4f1;
$accent-color-2: #f7f9fc;

$text-primary: $secondary-color;
$text-secondary: #181818;
$text-light: #fafafb;
$text-highlight: $primary-color;
$text-highlight-dark-background: $third-color;
