@import "../../styles/colors.scss";

button .animated-arrow-link {
  .item-underline {
    transition: 0s;
    height: 0;
  }
  &:hover {
    .item-underline {
      height: 0;
    }
  }
}

.animated-arrow-link {
  position: relative;
  cursor: pointer;
  &.text-light {
    .content-wrapper,
    .icon-wrapper svg {
      fill: $text-light;
      color: $text-light;
    }
  }
  .icon-wrapper {
    transform: translate(0px, 0px);
    transition: all 0.3s ease;
  }
  &:hover {
    .content-wrapper {
      color: $primary-color;
    }
    .icon-wrapper svg {
      fill: $primary-color;
    }
    color: $primary-color;
    .item-underline {
      width: 100%;
      background: $primary-color;
    }
    .icon-wrapper {
      transform: translate(15px, 0px) rotate(45deg);
    }
  }
  .item-underline {
    height: 2px;
    width: 0px;
    transition: all 0.4s ease;
  }
  .icon-section {
    width: 50px;
    position: relative;
  }
  .icon-wrapper {
    position: absolute;
    left: 10px;
  }
}
