$border-radius: 0px;

.inputWithButton {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  input {
    border: none;
    border-radius: $border-radius;
    padding-left: 15px;
    &:hover,
    &:active,
    &:focus {
      border: none;
    }
  }
  button {
    margin: 8px;
    border-radius: $border-radius !important;
  }
}
