@import "./colors";
@import "./forms";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fade {
  opacity: 1 !important;
}

.w-70 {
  width: 70%;
}

a {
  text-decoration: none;
}

body {
  position: relative;
}

ol li {
  margin-bottom: 1.2rem;
}

.page > *:first-child {
  .page-container {
    padding-top: 10rem;
    padding-bottom: 8rem;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.h-20 {
  height: 20%;
}

.h-30 {
  height: 30%;
}

.h-70 {
  height: 70%;
}

.highlight-quote-1 {
  border-left: 4px solid $primary-color;
  padding: 15px 30px;
}

.page-section {
  display: flex;
  align-items: center;
  width: 100%;
  .page-container {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  &.accent-color {
    background: $accent-color-2;
  }
}

.secondary-bg {
  background-color: $secondary-color;
  * {
    color: #fff;
    fill: #fff;
  }
}

.accent-bg-1 {
  background-color: $third-color;
}

.accent-bg-2 {
  background-color: $accent-color-2;
}

.card {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
      0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  }
  .content {
    padding: 16px;
  }
}

.box-shadow-1 {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.box-shadow-2 {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.box-shadow-3 {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

// .hover-scale-1 {
//   &:hover {
//     scale: 2;
//   }
// }

.no-shadow {
  box-shadow: none;
}

.border-none {
  border: none;
}

.flex-1 {
  flex: 1;
}

.bg-img-position-left {
  background-position: left;
}

.bg-img-position-right {
  background-position: right;
}

.bg-img-position-center {
  background-position: center;
}

.bg-img-position-bottom {
  background-position: bottom;
}

.bg-img-position-top {
  background-position: top;
}

.bg-img-size-auto {
  background-size: auto;
}

.bg-img-size-contain {
  background-size: contain;
}

.bg-img-size-cover {
  background-size: cover;
}

.bg-img-no-repeat {
  background-repeat: no-repeat;
}

.opacity-90 {
  opacity: 90%;
}

button {
  // font-weight: 400;
  border-radius: 0 !important;
  // font-size: 16px;
}

.fs-14px {
  font-size: 12px;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.cursor-pointer,
a {
  cursor: pointer;
}

.text-light {
  color: $text-light;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.glassEffect {
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}


@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

@media (min-width: 1200px) {
  .h-xl-100 {
    height: 100% !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .h-lg-100 {
    height: 100% !important;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
