.nav-dropdown-wrapper {
  width: 100%;
  position: absolute;
  opacity: 1;
  top: 130%;
  right: 0;
  margin-left: auto;
  display: none;
  opacity: 1;
  z-index: 9999;

  .nav-dropdown-container {
    padding: 44px 40px;
  }
  &.active {
    display: block;
    animation: scaleUp 0.5s;
  }
  .nav-dropdown {
    margin: auto;
  }
  .left-section-wrapper {
    display: grid;
    grid-template-rows: 30px 1fr 1fr;
    height: 100%;
  }
}
.dropdown-overlay {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

@keyframes scaleUp {
  from {
    opacity: 0.6;
    transform: translate(0, -20px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}
