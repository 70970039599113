#ComponentContentTabs {
  .contentTab {
    color: var(--bs-secondary);
    .section-icon {
      height: 30px;
      width: 30px;
      svg {
        height: 100%;
        width: 100%;
        fill: var(--bs-secondary);
        * {
          fill: var(--bs-secondary);
        }
      }
    }
    &.active,
    &:hover {
      color: var(--bs-primary-text-emphasis);
      svg {
        fill: var(--bs-primary);
        * {
          fill: var(--bs-primary);
        }
      }
    }
  }
}
