@import "../../styles/colors";

.navOuter {
  width: 100%;
  top: 0;
  right: 0;
  z-index: 99999;
  position: fixed;
}

.nav-wrapper {
  &.sideNavActive {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 9999;
    background: #fff;
    border-bottom: 1.5px solid $accent-color-2;
  }
  &::before {
    opacity: 0;
  }
  // &.top-announce-connect {
  //   &::before {
  //     background: rgb(13,110,253);
  //     height: 20px;
  //     width: 100%;
  //     content: "";
  //     position: absolute;
  //     z-index: -1;
  //     opacity: 1;
  //   }
  //   &.scroll::before, &.sideNavActive::before {
  //     opacity: 0;
  //   }
  //   background: white;
  //   border-radius: 13px 13px 0 0;
  // }

  &.scroll {
    box-shadow: 0px 6px 9pt -5px rgb(0 0 0 / 8%);
    background: #ffffff;
  }

  .nav-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    justify-content: space-between;
    margin: auto;
    position: relative;
    .nav-links {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      gap: 1.3rem;
      .link {
        &:hover {
          .nav-link:hover::before .nav-item-underline {
            opacity: 1;
            width: 100%;
          }
        }
      }
      .nav-link {
        margin: 0 12px 0 0;
        transition: all 0.4s ease;
        display: flex;
        align-items: center;

        &:hover {
          opacity: 0.7;
        }

        .link,
        .nav-drop-icon-wrapper {
          opacity: 0.4;
        }

        &.active {
          .nav-drop-icon-wrapper {
            opacity: 1;
          }
          .link {
            opacity: 1;
            .nav-item-underline {
              opacity: 1;
              width: 100%;
            }
          }
        }
        .link {
          &:hover {
            .nav-item-underline {
              opacity: 1;
              width: 100%;
            }
          }
        }
        &:hover {
          opacity: 1;
        }

        &:last-child {
          margin-right: 0;
        }

        &.active::before {
          width: 100%;
          opacity: 1;
        }

        .nav-drop-icon-wrapper {
          transform: translateY(1px);

          .arrow-icon {
            height: 6px;
            width: 6px;
            display: block;
            position: relative;
            cursor: pointer;
            margin: 0px 3.96px 0px 6px;
            transform: translateY(1.98px);

            span {
              position: absolute;
              background-color: $text-primary;
              top: 0px;
              width: 6px;
              height: 2px;
              display: block;
              float: right;
              border-radius: 2px;
              transform-origin: center center;
              transition: transform 0.3s ease-in-out;

              &:first-of-type {
                left: 0px;
                transform: rotate(45deg);
              }

              &:last-of-type {
                left: 3px;
                transform: rotate(-45deg);
              }
            }
          }

          &.dropdown-active {
            .arrow-icon {
              span:first-of-type {
                transform: rotate(320deg);
              }

              span:last-of-type {
                transform: rotate(45deg);
              }
            }
          }
        }

        .link {
          text-decoration: none;
          text-transform: uppercase;
          display: block;
          font-weight: 500;
          letter-spacing: 0.05em;
          font-size: 14px;
          color: $text-primary;
          cursor: pointer;
          position: relative;

          .nav-item-underline {
            transition: all 0.4s ease;
            content: "";
            height: 2px;
            width: 100%;
            position: absolute;
            top: 20px;
            bottom: 0;
            margin: 10px 0 0 0;
            background: $primary-color;
            width: 0%;
            opacity: 0;
          }
        }
      }
    }
  }

  .nav-cta {
    display: none;
  }

  .main-menu {
    display: none;
    justify-content: space-between;
    width: auto;
    align-items: center;

    .nav-dropdown {
      background-color: white;
      // background-color: $secondary-color;
      border-radius: 10px;
      padding: 0;
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

      .left-column {
        padding-right: 0 !important;
        padding-top: 30px !important;
        padding-left: 30px !important;
        padding-bottom: 30px !important;

        .left-section-wrapper {
          .title {
            color: #fafafb !important;
            line-height: 19px;
            padding-bottom: 0;
            margin-bottom: 30px;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
          }

          .menu {
            border: 0;

            .item {
              border-right: 0 !important;
              color: #fafafb !important;

              &.active {
                color: $secondary-color !important;
                font-weight: 500;
              }
            }
          }
        }
      }

      .right-column {
        padding-right: 0 !important;
        padding-top: 30px !important;
        padding-right: 30px !important;
        padding-left: 30px !important;
        padding-bottom: 30px !important;
        background: #fff;
        border-radius: 0 10px 10px 0;

        .right-section-wrapper {
          background: #ffffff;
          .feature-list-item {
            &:hover {
              a,
              .title {
                color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

#menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  background: rgba(34, 34, 34, 0.5);
  transform: translateZ(0);
  display: none;

  &.active {
    visibility: inherit;
    opacity: 1;
    display: block;
  }
}

.hamburger-wrapper {
  width: 30px;
  height: 30px;
  .hamburger {
    border: 0;

    .hamburger-text-wrap {
      opacity: 0;
    }
  }
}

.hamburger-wrapper {
  width: 50px;
  height: 50px;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;

  .hamburger {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 1px solid #007acc;
    background: #ffffff;
    outline: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;

    .hamburger-text-wrap {
      .hamburger-text {
        position: absolute;
        top: 50%;
        left: -48px;
        display: block;
        width: 36px;
        height: 20px;
        margin-top: -10px;
        font-size: 10px;
        font-weight: 600;
        text-align: right;
        line-height: 20px;
        letter-spacing: 0.1em;
        color: #007acc;
      }

      .hamburger-text-open {
        visibility: inherit;
        opacity: 1;
      }

      .hamburger-text-close {
        visibility: hidden;
        opacity: 0;
      }
    }

    .hamburger-line-wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 22px;
      height: 17px;
      display: block;
      margin: auto;

      .hamburger-line {
        width: 100%;
        height: 3px;
        background: #007acc;
        position: absolute;
        left: 0;
        transition: all 200ms;

        &.hamburger-line-1 {
          transform: matrix(1, 0, 0, 1, 0, 0);
          top: 0;
        }

        &.hamburger-line-2 {
          visibility: inherit;
          opacity: 1;
          top: 7px;
          width: 13px;
        }

        &.hamburger-line-3 {
          transform: matrix(1, 0, 0, 1, 0, 0);
          top: 14px;
        }
      }
    }

    &.close {
      background: $primary-color;
      border-color: $primary-color;

      .hamburger-text-wrap {
        .hamburger-text-close {
          visibility: inherit;
          opacity: 1;
        }

        .hamburger-text-open {
          visibility: hidden;
          opacity: 0;
        }
      }

      .hamburger-line-wrap {
        .hamburger-line {
          background: #ffffff;

          &.hamburger-line-1 {
            transform: matrix(0.7071, 0.7071, -0.7071, 0.7071, 0, 7);
          }

          &.hamburger-line-2 {
            visibility: hidden;
            opacity: 0;
          }

          &.hamburger-line-3 {
            transform: matrix(0.7071, -0.7071, 0.7071, 0.7071, 0, -7);
          }
        }
      }
    }
  }

  &:hover {
    .hamburger {
      .hamburger-line-wrap {
        .hamburger-line.hamburger-line-2 {
          width: 100%;
        }
      }
    }
  }
}

#menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(34, 34, 34, 0.5);
  transform: translateZ(0);
  display: none;
  visibility: invisible;

  &.active {
    visibility: inherit;
    opacity: 1;
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .nav-wrapper {
    .nav-mobile-menu-icon {
      display: none;
    }

    #menu-overlay {
      display: none;
    }

    .nav-container {
      .nav-cta {
        display: block;
      }

      .main-menu {
        display: flex;
      }

      nav {
        .menu-main {
          display: block;
          width: auto;
        }
      }
    }

    #menu-overlay {
      display: none;
      visibility: invisible;

      &.active {
        visibility: inherit;
        opacity: 1;
        display: none;
      }
    }
  }

  .sidenav-container {
    display: none;

    &.show {
      display: none;
    }
  }
}
