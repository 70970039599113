@import '../../styles/colors';

#featuredBlogSection {
	position: relative;
	.container {
		&::after {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='p' width='100' height='100' patternUnits='userSpaceOnUse' patternTransform='scale(0.45)'%3E%3Ccircle data-color='outline' fill='none' stroke='%237D7E7F' stroke-width='10.74' cx='50' cy='50' r='.5'%3E%3C/circle%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23p)' width='100%25' height='100%25'%3E%3C/rect%3E%3C/svg%3E");
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			content: "";
			opacity: 0.6;
			bottom: 0;
			right: 10%;
			width: 20%;
			height: 40%;
			z-index: -1;
		}
	}
	.blog-row {
		row-gap: 24px;
	}
	.blog-card {
		height: 235px;
		transition: all 0.3s ease-in-out;
		background: #fff;
		cursor: pointer;
		.card-img-container {
			transition: all 0.4s ease-in-out;
			border-radius: 10px;
		}
		.card-title {
			transition: all .2s cubic-bezier(1, 0, 0, 1);
		}
		&:hover {
			.card-img-container {
				transform: translate(0px, -10px);
			}

		}
	}
}
