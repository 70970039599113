#list-accordion {
  .list-accordion-item {
    transition: all 0.3s ease-in-out;
    .accordionIcon {
      transition: all 0.3s ease-in-out;
    }
    .description {
      max-height: 0px;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &.active {
      .description {
        max-height: 1000px;
        overflow: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
      .accordionIcon {
        transform: rotate(180deg);
      }
    }
  }
}
