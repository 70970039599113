#carouselDots {
  .carouselDot {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background: #dadada;
    &.active {
      background: var(--bs-primary);
    }
  }
}
