@import "../../styles/colors";

#getStartedSection {
  .page-container {
    background: #0f1681;
    background-size: cover;
    button.primary {
      background: $third-color;
      font-weight: 500;
    }
    button.basic {
      box-shadow: inset 0 0 0 1px $third-color;
      color: $third-color;
    }
  }
}
