@import "../../../styles/colors.scss";

.sidenav-container {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 300;
	top: 0;
	right: 0;
	background-color: #ffffff;
	padding-top: 60px;
	transition: 0.5s;
	margin: 0;
	padding: 60px 0 0 0;

	&.show {
		width: 100%;
	}
}

@media screen and (min-width: 1200px) {
	.sidenav-container {
		display: none;
		&.show {
			display: none;
		}
	}
}
