#homePage {
    #dataDrivenCulturePageSection {
        position: relative;

        .list-card-wrap {
            display: grid;
            grid-template-columns: 200px 200px;
            grid-template-rows: 20px 200px 20px 20px 200px;
            text-align: center;
            justify-content: center;
            grid-column-gap: 20px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                height: 400px;
                width: 400px;
                background-color: #fcfcfc;
                opacity: 0.4;
                background-image: radial-gradient(#383838 1.2px, #fcfcfc 1.2px);
                background-size: 24px 24px;
                right: 5%;
                top: -10%;
            }
            
            .customCard {
                height: 200px;
                width: 200px;
                &:hover {
                    transform: scale(1.1);
                }
                .img-wrapper {
                    height: 123.609394314px;
                    img {
                        width: 76.3964118133px;
                    }
                }
                &:nth-child(1) {
                    grid-row-start: 2;
                    grid-row-end: 3;
                    grid-column-start: 1;
                    
                }
                &:nth-child(2) {
                    grid-row-start: 1;
                    grid-row-end: 2;
                    grid-column-start: 2;
                }
                &:nth-child(3) {
                    grid-row-start: 4;
                    grid-row-end: 5;
                    grid-column-start: 1;
                }
                &:nth-child(4) {
                    grid-row-start: 3;
                    grid-row-end: 5;
                    grid-column-start: 2;
                }
                
            }
        }
        
    }
}