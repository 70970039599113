@import "../../styles/colors";

.social-icons {
  display: flex;
  color: $text-primary;

  .social-icon-link {
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin: 0 7px;

    &:hover {
      opacity: 1;
      transform: translate(0px, -5px);
    }
  }

  &.rounded {
    .social-icon-link {
      border-radius: 100%;
      width: 50px;
      height: 50px;
      border: 2px solid $accent-color-2;
      min-width: 50px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      background: white;
      &:hover {
        transform: translate(0px, -10px);
      }
    }
  }
  &.colored {
    opacity: 1;
    .youtube svg {
      fill: #ff0200;
    }
    .twitter svg {
      fill: #00a9f4;
    }
    .linkedIn svg {
      fill: #0277b7;
    }
  }
  &.dark {
    * {
      color: $text-light;
    }
  }
}
