#announcement-topbar {
   font-size: 14px;
    width: 100%;
    // height: 50px;
    min-height: 50px;
    max-height: max-content;
    .inner {
        min-height: 50px;
    }
    .close-icon {
        right: 20px;
        position: absolute;
    }
    
    .getStartedText {
        &.text-info svg{
            fill: rgb(13, 202, 240)
        }
        
    }
    a {
        font-size: inherit !important;
    }
    @media screen and (max-width: 786px) {
        font-size: 14px;
        
    }

}