@import "../../../styles/colors.scss";

.hero-section {
	height: 100vh;
	width: 100vw;
	overflow: scroll;
	box-sizing: border-box;
	background: #ffffff;
	padding-top: 55px;
	overflow: hidden;
	&:before {
		// content: "";
		// position: absolute;
		// width: 100%;
		// height: 100%;
		// background-color: $accent-color;
		// top: 0;
		// left: 0;
		// z-index: 0;
	}
	.form-box {
		min-height: 500px;
		column-gap: 20px;
		.fields {
			display: flex;
			align-items: center;
			column-gap: 20px;
			row-gap: 15px;
			flex-wrap: wrap;
			width: 100%;
			.input-box {
				padding-top: 20px;
				position: relative;
				transition: all 0.3s ease-in-out;
				.label-text {
					position: absolute;
					font-size: 14px;
					top: 50%;
					left: 10px;
					transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
				}
				select {
					width: 100%;
					-webkit-appearance: none;
					-moz-appearance: none;
					text-indent: 1px;
					background: #ffffff;
				}
				input,
				select {
					width: 100%;
					padding: 0.678571em 2.2em 0.678571em 1em;
					font-size: 1em;
					border: 1px solid rgba(34, 36, 38, 0.15);
					border-radius: 0.28571429rem;
					color: $secondary-color;
					transition: color 0.1s ease, border-color 0.1s ease;
					&::placeholder {
						opacity: 0;
					}
					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
				}
				.inputImageIcon {
					position: absolute;
					right: 10px;
				}
				&.focus {
					.label-text {
						font-size: 12px;
						top: 0;
						left: 0;
					}
					input {
						&::placeholder {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
