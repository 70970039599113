#agentUseCasesSection {
  background-image: url("data:image/svg+xml,%3Csvg width='1512' height='982' viewBox='0 0 1512 982' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1_2)'%3E%3Crect width='1512' height='982' fill='%230C6EFD'/%3E%3Cg filter='url(%23filter0_f_1_2)'%3E%3Cpath d='M189.381 668L543.912 761.165L602.61 487.5L944.394 624.112L1017.61 330.5L1312.11 571.5L1358.11 246' stroke='url(%23paint0_linear_1_2)' stroke-width='203' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_1_2' x='-32.3444' y='24.2873' width='1612.17' height='958.578' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='60.1' result='effect1_foregroundBlur_1_2'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear_1_2' x1='481.881' y1='792' x2='910.381' y2='426.5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23F187FB'/%3E%3Cstop offset='1' stop-color='%2360EFFF'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_1_2'%3E%3Crect width='1512' height='982' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  .page-container {
    min-height: 700px;
  }
}

.glassMorphismBackground {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.24) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.9px);
  -webkit-backdrop-filter: blur(5.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
