#story-page {
  .storyCoverImage {
    background-repeat: no-repeat;
    min-height: 100%;
    height: 100vh;
    max-height: 500px;
    background-position: center;
    background-size: cover;
  }
}
