@import "../../styles/colors.scss";

#slideshow-action-buttons {
  .left-action,
  .right-action {
    transition: all 0.3s ease-in-out;
    &:hover {
      border: 0;
      background-color: var(--bs-primary);
      svg {
        fill: #fff;
      }
    }
  }
}
