@import "./colors";

.cyn-form {
  label {
    font-size: 14px;
  }
  input,
  select {
    height: 63px;
    padding-right: 30px;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  input[type=datetime-local] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  input,
  textarea,
  select {
    margin-bottom: 0px;
    padding-right: 16px;
    padding-left: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: #e9eaf0;
    border-radius: 0;
    -webkit-appearance:none;
    -moz-appearance: none;
    &:hover,
    &.active,
    &:focus {
      -webkit-appearance:none;
      -moz-appearance: none;
      border: 1px solid $primary-color;
      box-shadow: none;
    }
  }
  select,
  input[type="datetime-local"] {
    &:invalid {
      color: grey;
    }
  }
  input[type="checkbox"] {
    width: 1em;
    height: 1em !important;
    padding-right: 0px;
    padding-left: 0px;
  }
}
