@import "../../styles/colors";

.multi-level-accordion-nav-items {
	margin-top: 40px;

	ul {
		list-style: none;
		padding: 0;
		li {
			text-align: left;
			padding: 20px 16px;
			border-bottom: 1px solid #d5dce5;
			&.border-none {
				border: 0;
			}
			.nav-link {
				padding: 10px 8px;
			}

			.link {
				font-size: 18px;
				line-height: 1.5;
				text-decoration: none;
				position: relative;
				font-weight: 500;
				color: $secondary-color;
				cursor: pointer;
				opacity: 0.7;
				&.selected {
					opacity: 1;
					&:after {
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M18.9 35.7 7.7 24.5 9.85 22.35 18.9 31.4 38.1 12.2 40.25 14.35Z'/%3E%3C/svg%3E");
						position: absolute;
						right: 0;
						width: 20px;
						height: 20px;
						background-position: center;
						background-size: cover;
						content: "";
					}
				}
			}
			.nav-drop-icon-wrapper {
				transform: translateY(1px);

				.arrow-icon {
					height: 6px;
					width: 6px;
					display: block;
					position: relative;
					cursor: pointer;
					margin: 0px 3.96px 0px 6px;
					transform: translateY(1.98px);

					span {
						position: absolute;
						background-color: $text-primary;
						top: 0px;
						width: 6px;
						height: 2px;
						display: block;
						float: right;
						border-radius: 2px;
						transform-origin: center center;
						transition: transform 0.3s ease-in-out;

						&:first-of-type {
							left: 0px;
							transform: rotate(45deg);
						}

						&:last-of-type {
							left: 3px;
							transform: rotate(-45deg);
						}
					}
				}
			}
			.accordionTitle {
				transition: all 0.3s ease-in-out;
				opacity: 1;
				cursor: pointer;
				&.active {
					.nav-drop-icon-wrapper {
						.arrow-icon {
							span:first-of-type {
								transform: rotate(320deg);
							}

							span:last-of-type {
								transform: rotate(45deg);
							}
						}
					}
				}
			}
			.accordion {
				transition: all 0.3s ease-in-out;
				.content {
					display: none;
				}
				&.active {
					.content {
						display: block;
					}
				}
			}
		}
	}
}
