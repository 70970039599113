@import "../../styles/colors";

#footer-section {
  h2,
  h5,
  button,
  p,
  a,
  .footer-link {
    color: #fff;
  }
  .icon-section .icon-wrapper svg {
    fill: #616d7e;
  }

  a {
    &:hover {
      color: #ffffff;
    }
  }
  .animated-arrow-link {
    &:hover {
      .content-wrapper {
        color: #fff;
      }
      .icon-section .icon-wrapper svg {
        fill: #ffffff;
      }
    }
  }

  a,
  .footer-link,
  .link,
  .content-wrapper {
    color: #616d7e;
    font-size: 16px;
    &:hover {
      color: #fff;
    }
  }
  li {
    margin-bottom: 0.3rem;
  }
}

.copyright-section {
  border-top: $text-light;
}
