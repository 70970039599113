@import "./colors";

$button-primary: #0c6efd;

.button {
  font-weight: 400;
  padding: 0.78571429em 1.5em 0.78571429em;
  border: 0;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
  &:hover {
    transform: translate(0px, -4px);
  }
  &.large {
    font-size: 1.14285714rem;
  }
  &.big {
    font-size: 1.28571429rem;
  }
  &.small {
    font-size: 0.92857143rem;
  }
  &.tiny {
    font-size: 0.85714286rem;
  }

  &.dark {
    background-color: $text-primary;
    &.basic {
      box-shadow: inset 0 0 0 1px $text-primary;
      color: $text-primary;
    }
  }

  &.primary {
    background: $button-primary;
    &.basic {
      box-shadow: inset 0 0 0 1px $button-primary;
      color: $button-primary;
    }
  }
  &.light {
    background: #fff;
    color: $text-primary;
  }
  &.secondary {
    font-size: 1.14285714rem;
  }

  &.basic {
    background: transparent;
    color: $text-secondary;
    box-shadow: inset 0 0 0 1px #d8dae3;
    &:hover {
      box-shadow: inset 0 0 0 1px $button-primary;
      color: $button-primary;
    }
  }
}
