.slideshow-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  .slideshow-images {
    display: flex;
    overflow: hidden;
  }

  .slide {
    flex: 0 0 100%;
    opacity: 0;
    transition: opacity 1s ease;
    order: 2; /* Initial order */
    &.active {
      order: 1;
    }
    img {
      width: 100%;
      height: auto;
    }

    &.active {
      opacity: 1;
    }
  }

  .dot-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #bbb;
      margin: 0 5px;
      cursor: pointer;

      &.active {
        background-color: var(--bs-primary);
      }
    }
  }
}
